<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="批量修改"
    v-model="dialogFormVisible"
    width="40%"
  >
    <div class="main">
      <div class="each" style="width: 60%">
        <p>
          （1）点击导出选择企业的基本信息
          <el-button type="primary" size="small" @click="loadValue()"
            >导出</el-button
          >
        </p>
        <p>（2）自主修改excel字段信息并保存</p>
        <p>（3）导入修改后的excel，批量修改企业信息</p>
        <p style="text-indent: 2em; display: flex">
          <el-input
            style="width: 250px; margin-right: 10px"
            v-model="path"
            size="small"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </p>
      </div>
      <div class="each" style="width: 30%; margin-left: 30px"></div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="uploadExcel()"
          :loading="daoruLoading"
          >上传修改</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogTableVisible"
    title="提示"
  >
    <el-table :data="callList" border height="400px">
      <template #empty>
        <el-empty :image-size="160" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="公司名称" min-width="180">
        <template #default="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="失败原因" min-width="180">
        <template #default="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { commonTemplate } from "@/api/export";
import { companyImport } from "@/api/import";
export default {
  name: "allotEdit",
  props: {
    listQuery1: {
      type: Object,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      editType: "",
      city: [],
      sels: [],
      path: "",
      excelList: [],
      address: "",
      rawFile: "",
      daoruLoading: false,
      callList: [],
      dialogTableVisible: false,
    };
  },
  methods: {
    init(e) {
      let ids = [];
      e.map((v) => {
        ids.push(v.id);
      });
      this.sels = ids;
      this.dialogFormVisible = true;
    },
    //导出
    loadValue() {
      let param = {
        comIds: this.sels,
        type: "clientCompany",
        query: this.listQuery1,
      };
      commonTemplate(param).then((res) => {
        window.open(res.data.data.path);
      });
    },
    //导入
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      companyImport(param).then((res) => {
        this.daoruLoading = false;
        if (res.data.data && res.data.data.length != 0) {
          this.callList = res.data.data;
          this.dialogTableVisible = true;
        } else {
          this.$qzfMessage("修改成功");
          this.dialogFormVisible = false;
          this.$emit("success");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  display: flex;
  p {
    margin-bottom: 10px;
  }
}
</style>
