<template>
  <!-- 彻底删除客户 -->
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="提示"
    v-model="dialogVisible"
    width="25%"
  >
    <el-form
      :model="form"
      label-width="100px"
      label-position="right"
      size="small"
    >
      <div><span style="color:red">*</span> 请输入软件密码确认身份：</div>
      <div style="margin:10px 0 20px">
        <el-input
          v-model="form.password"
          style="width:100%;"
          size="small"
        ></el-input>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="delSure" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { delCompanyNew } from "@/api/company";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        password: "",
      },
      coms: [],
    };
  },
  methods: {
    init(rows) {
      this.coms = rows;
      this.form = {
        password: "",
      };
      this.dialogVisible = true;
    },
    delSure() {
      if (!this.form.password) {
        this.$qzfMessage("请填写密码", 1);
        return;
      }
      this.coms.map((v) => {
        v.status = 9;
      });
      let param = {
        eaCompany1: this.coms,
        password: this.form.password,
      };
      delCompanyNew(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("删除成功");
          this.dialogVisible = false;
          this.$emit("success");
        }
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
